import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory as useRouterHistory } from 'react-router-dom';
import {
  setSessionContestPage,
  setSessionContests,
  setSessionContestTotalPage,
} from '../persistence/session.storage';
import {
  setContestPageAction,
  setContestsAction,
  setContestSubmissionsAction,
  setContestTotalPagesAction,
  updateContestAction,
  updateContestSubmissionAction,
} from '../store/actions';

export const useHistory = () => {
  const history = useRouterHistory();
  return {
    ...history,
    push: (to) => {
      setTimeout(() => history.push(to), 300);
    },
    goBack: () => setTimeout(history.goBack, 300),
    goForward: () => setTimeout(history.goForward, 300),
  };
};

export const useContests = () => {
  const { contests, contestPage, contestTotalPages } = useSelector((state) => state);
  useEffect(() => {
    setSessionContests(contests);
  }, [contests]);
  useEffect(() => {
    setSessionContestPage(contestPage);
  }, [contestPage]);
  useEffect(() => {
    setSessionContestTotalPage(contestTotalPages);
  }, [contestTotalPages]);
  const dispatch = useDispatch();
  const setContests = (contestObj) => {
    dispatch(setContestsAction(contestObj));
  };
  const updateContest = (update) => {
    dispatch(updateContestAction(update));
  };
  const setContestSubmissions = ({ _id, submissions }) => {
    dispatch(setContestSubmissionsAction({ _id, submissions }));
  };
  const updateContestSubmission = ({ _id, submission }) => {
    dispatch(updateContestSubmissionAction({ _id, submission }));
  };
  const setTotalPages = (total) => {
    dispatch(setContestTotalPagesAction(total));
  };
  const setPage = (page) => {
    dispatch(setContestPageAction(page));
  };
  const onReactToContest = (contest) => {
    const getCountByIntent = (it) => (it ? 1 : -1);
    const getValByIntent = (it) => (it ? 1 : 0);
    const handleLoveChange = (intent) => {
      updateContest({
        ...contest,
        reactions: {
          ...(contest.reactions || {}),
          heart: {
            count:
            contest?.reactions?.heart?.count
              ? contest?.reactions?.heart?.count + getCountByIntent(intent)
              : getValByIntent(intent),
          },
        },
        myReactions: {
          ...(contest.myReactions || {}),
          heart: intent,
        },
      });
    };
    return {
      handleLoveChange,
    };
  };
  return {
    contests,
    totalPages: contestTotalPages,
    page: contestPage,
    setContests,
    updateContest,
    setContestSubmissions,
    updateContestSubmission,
    setTotalPages,
    setPage,
    onReactToContest,
  };
};
