import React, { useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { AppContextProvider } from './App.context';
import Routing from './routes/Routing.component';
// css imports
import './assets/css/animate.min.css';
import './assets/css/main.css';
import { getVoterId, setVoterId } from './components/vote-submission/storage';
import { FirebaseContextProvider } from './Firebase.context';

function App() {
  useEffect(() => {
    setVoterId(getVoterId() ?? uuid().replace(/-/g, ''));
  }, []);
  return (
    <AppContextProvider>
      <FirebaseContextProvider>
        <Routing />
      </FirebaseContextProvider>
    </AppContextProvider>
  );
}

export default App;
