import { lazy } from 'react';
import { authGuard } from './guards/auth.guard';
import { guestGuard } from './guards/guest.guard';
import { paths } from './paths';

export const routes = [
  {
    path: paths.LANDING,
    page: lazy(() => import('../pages/landing/Landing.page')),
  },
  {
    path: paths.AUTH,
    page: lazy(() => import('../pages/auth/Auth.page')),
    guards: [guestGuard],
  },
  {
    path: paths.HOME,
    page: lazy(() => import('../pages/landing/Landing.page')),
    guards: [authGuard],
  },
  {
    path: paths.FORGOTPASSWORD,
    page: lazy(() => import('../pages/forgot-password/ForgotPassword.page')),
  },
  {
    path: paths.CONTEST_PARTICIPATING,
    page: lazy(() => import('../pages/contest-participating/ContestParticipating.page')),
    guards: [authGuard],
  },
  {
    path: paths.CONTEST_DETAILS,
    page: lazy(() => import('../pages/contest-details/ContestDetails.page')),
  },
  {
    path: paths.RESETPASSWORD,
    page: lazy(() => import('../pages/reset-password/ResetPassword.page')),
  },
  {
    path: paths.MY_WORK,
    page: lazy(() => import('../pages/my-work/MyWork.page')),
    guards: [authGuard],
  },
  {
    path: paths.CONTEST_ORGANIZING_BUILDER,
    page: lazy(() => import('../pages/contest-builder/ContestBuilder.page')),
    guards: [authGuard],
  },
  {
    path: paths.CONTEST_SUBMISSIONS,
    page: lazy(() => import('../pages/contest-submission/ContestSubmission.page')),
  },
  {
    path: paths.SUBMISSION,
    page: lazy(() => import('../pages/contest-submission/ContestSingleSubmission.page')),
  },
  {
    path: paths.REDIRECT,
    page: lazy(() => import('../pages/_3xx/R3xx.page')),
  },
  {
    page: lazy(() => import('../pages/_404/E404.page')),
  },
];
