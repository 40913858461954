export const REFS = {
  JWT: 'k-0001',
  REFRESH_TOKEN: 'k-0002',
  EXPIRES_IN_SECONDS: 'k-0003',
  EXPIRES_AT: 'k-00895',
  USER: 'k-0004',
  LANGUAGE: 'k-00055',
};

export const setJWT = (jwtStr) => {
  localStorage.setItem(REFS.JWT, jwtStr);
};

export const getJWT = () => localStorage.getItem(REFS.JWT);

export const setRefreshToken = (rStr) => {
  localStorage.setItem(REFS.REFRESH_TOKEN, rStr);
};

export const getRefreshToken = () => localStorage.getItem(REFS.REFRESH_TOKEN);

export const setExpiresInSeconds = (exp) => {
  localStorage.setItem(REFS.EXPIRES_AT, Date.now() + (exp * 1000));
  localStorage.setItem(REFS.EXPIRES_IN_SECONDS, exp);
};

export const getExpiresInSeconds = () => localStorage.getItem(REFS.EXPIRES_IN_SECONDS);

export const getExpiresAt = () => localStorage.getItem(REFS.EXPIRES_AT);

export const setLocalUser = (uStr) => {
  localStorage.setItem(REFS.USER, uStr);
};
export const clearLocalUser = () => {
  localStorage.removeItem(REFS.USER);
};
export const clearTokens = () => {
  localStorage.removeItem(REFS.EXPIRES_IN_SECONDS);
  localStorage.removeItem(REFS.JWT);
  localStorage.removeItem(REFS.REFRESH_TOKEN);
  localStorage.removeItem(REFS.EXPIRES_AT);
};

export const getLocalUser = () => localStorage.getItem(REFS.USER);

export const setLanguage = (lang) => localStorage.setItem(REFS.LANGUAGE, lang);

export const getLanguage = () => localStorage.getItem(REFS.LANGUAGE);
