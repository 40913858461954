export const SET_USER = 'SET_USER';
export const LOADER = 'LOADER';
export const STACK_TOASTER_MESSAGE = 'STACK_TOASTER_MESSAGE';
export const SLICE_TOASTER_MESSAGE = 'SLICE_TOASTER_MESSAGE';
export const SET_CONTESTS = 'SET_CONTESTS';
export const UPDATE_CONTEST = 'UPDATE_CONTEST';
export const SET_CONTEST_SUBMISSIONS = 'SET_CONTEST_SUBMISSIONS';
export const UPDATE_CONTEST_SUBMISSION = 'UPDATE_CONTEST_SUBMISSION';
export const SET_CONTEST_PAGE = 'SET_CONTEST_PAGE';
export const SET_CONTEST_TOTAL_PAGES = 'SET_CONTEST_TOTAL_PAGES';

export const setUserAction = (user) => ({
  type: SET_USER,
  payload: user,
});

export const setLoaderAction = (payload) => ({
  type: LOADER,
  payload,
});

export const stackToasterMessageAction = (payload) => ({
  type: STACK_TOASTER_MESSAGE,
  payload,
});

export const sliceToasterMessageAction = () => ({
  type: SLICE_TOASTER_MESSAGE,
});

export const setContestsAction = (payload) => ({
  type: SET_CONTESTS,
  payload,
});

export const updateContestAction = (payload) => ({
  type: UPDATE_CONTEST,
  payload,
});

export const setContestSubmissionsAction = (payload) => ({
  type: SET_CONTEST_SUBMISSIONS,
  payload,
});

export const updateContestSubmissionAction = (payload) => ({
  type: UPDATE_CONTEST_SUBMISSION,
  payload,
});

export const setContestPageAction = (payload) => ({
  type: SET_CONTEST_PAGE,
  payload,
});

export const setContestTotalPagesAction = (payload) => ({
  type: SET_CONTEST_TOTAL_PAGES,
  payload,
});
