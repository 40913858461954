import { createContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyAilBwFccAWZtllHjmusOkB3XvW_Nzvc1o',
  authDomain: 'mungwin-shield-328508.firebaseapp.com',
  projectId: 'mungwin-shield-328508',
  storageBucket: 'mungwin-shield-328508.appspot.com',
  messagingSenderId: '140540602699',
  appId: '1:140540602699:web:407f14659d6d8bb15760ce',
  measurementId: 'G-CNNPL5G9D9',
};

const FirebaseContext = createContext();
export const FirebaseContextProvider = ({ children }) => {
  const { i18n: { language } } = useTranslation();
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const firebaseAuth = getAuth();
  firebaseAuth.languageCode = language;
  return (
    <FirebaseContext.Provider value={{
      app,
      analytics,
      firebaseAuth,
    }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FirebaseContext;
