import styled from '@emotion/styled';

const getPosition = (position) => {
  switch (position) {
    case 'bottom':
    case 'bottom-left':
      return 'bottom: 80px; left: 80px';
    case 'top-right':
      return 'top: 80px; right: 80px';
    case 'bottom-right':
      return 'bottom: 80px; right: 80px';
    case 'top':
    case 'top-left':
    default:
      return 'top: 80px; left: 80px';
  }
};
export const StyledSnackWrapper = styled.div`
    position: fixed;
    ${({ position }) => getPosition(position)};
    z-index: 9990;
`;

export const StyledSnack = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 16px;
    & span {
      word-break: break-word;
    }
    & button {
      background-color: transparent;
      color: var( --color-medium-shade);
      outline: none;
      border: none;
      cursor: pointer;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5px;
      margin-top: -5px;
    }
    & button: hover {
      box-shadow: var(--box-shadow);
    }
`;
