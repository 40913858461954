const appConfig = {};

export const getAppConfig = () => Promise.resolve({
  security: {
    server_url: 'https://mshieldpro.herokuapp.com',
  },
  contest: {
    server_url: 'https://talentid.herokuapp.com',
  },
  notifications: {
    server_url: 'https://m-notifypro.herokuapp.com',
  },
  google: {
    client_id: '140540602699-cqf6ii68ea0po5i3kvsf8a9oppvsp1al.apps.googleusercontent.com',
    project_id: 'mungwin-shield-328508',
    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
    token_uri: 'https://oauth2.googleapis.com/token',
  },
  facebook: {
    sdk_url: 'https://connect.facebook.net/en_US/sdk.js',
    app_id: '709776036651690',
  },
  tiny: {
    key: 'bvou2s3tv2oyxg9ikd632fiht77516pijmrvao1tjy3fvcln',
  },
});

export const setAppConfig = (config) => {
  Object.assign(appConfig, config);
};

export const securityConfig = {

};

export const contestConfig = {
  ID: '619e7281a807beca7189dbc0',
  SECRET: 'tal234S3Kr3t-+sm011',
  KEY: 'ecc4016ab0704e2ba070266b3744734b',
};

export const notificationsConfig = {
  ID: '619e176558b6a1a5d34c22fc',
};

export const appName = 'Mungwin';

export default appConfig;
