const translation = {
  start: 'Commencer',
  'make-impression': 'Faire une Impression',
  login: 'Connexion',
  signup: 'S\'inscrire',
  'login-with-phone': 'Se connecter avec le téléphone',
  'login-with-facebook': 'Se connecter avec facebook',
  'login-with-google': 'Se connecter avec Google',
  'privacy-consent-ok': 'By clicking {{page}}, you agree to/with our Terms. You can see how we process your data in our',
  'privacy-policy': 'Privacy Policy',
  'cookies-policy': 'Cookies Policy',
  'having-trouble-to': 'Having trouble to {{action}}?',
  'i-agree': 'J\'accepte',
  male: 'Mâle',
  female: 'Femelle',
  'have-a-profile': 'Avez-vous un profil?',
  'code-conduct-prompt': '{{appName}} est créé pour donner aux célibataires une chance de trouver leur véritable partenaire. Si vous ne respectez pas les règles suivantes, votre profil sera banni.',
  'create-your-profile': 'Créez votre profile',
  'create-an-honest-profile': 'Créer un profil honnête',
  'with-profile-benefit': 'Grâce à cela, vous pourrez rencontrer des personnes qui correspondent à ce que vous recherchez chez un partenaire. S\'il vous plaît, soyez honnête. La malhonnêteté peut entraîner des sanctions.',
  'add-a-photo-of-yourself': 'Ajoutez une photo de vous',
  'your-face-photo-benefits': 'Nous avons vu qu\'une bonne photo de votre visage et de votre corps entier peut conduire à de meilleures rencontres. Cependant, nous n\'exigeons qu\'une seule photo, avec votre visage.',
  'be-respectful-and-polite': 'Soyez respectueux et poli',
  'respect-others': 'Veuillez traiter les autres utilisateurs avec le plus grand respect. Être respectueux et poli vous donnera plus de chances d\'avoir une rencontre fructueuse.',
  'signing-you-in': 'Connexion, veuillez patienter...',
  authenticating: 'Authentification, veuillez patienter...',
  working: 'En cours, veuillez patienter...',
  next: 'Suivant',
  'what-s-your-name': 'Comment t\'appelles-tu ?',
  'how-name-will-appear': 'Voici comment votre nom apparaîtra sur {{appName}}',
  'nice-to-meet-u': 'Ravi de vous rencontrer, {{name}}',
  'what-s-ur-birthday': 'Quelle est votre date de naissance??',
  'pay-with': 'Payer avec',
  'order-details': 'Détails de la commande',
  'input-phone-return-email': 'Veuillez saisir le numéro de téléphone pour le paiement et l\'e-mail pour la notification de réussite',
  phone: 'Téléphone',
  'return-email': 'L\'e-mail de retour',
  'enter-phone': 'Veuillez saisir votre numéro de téléphone ici',
  'enter-return-email': 'Veuillez saisir votre e-mail ici',
  summary: 'Le Relevé',
  'item-description': 'Description',
  'per-unit': 'Par Unité',
  quantity: 'Quantité',
  total: 'Totale',
  'original-price': 'Prix d\'origine',
  discount: 'Remise',
  'total-price': 'Prix total',
  proceed: 'Continuer',
  'your-votes': 'Vos votes',
  'no-votes-to-display': 'Vous n\'avez enregistré aucun vote. Vous avez peut-être voté en tant qu\'invité d\'honneur',
  'vote-in-progress': 'Vote en cours. Vous serez averti une fois votre paiement confirmé! Merci!',
  'allow-popup': 'Vous devrez peut-être autoriser les fenêtres contextuelles sur votre navigateur pour continuer',
  'popup-closed': 'Vous avez fermé la fenêtre contextuelle de manière inattendue.',
  'email-not-verified': 'Email non vérifié. Si vous l\'avez déjà fait, veuillez vous déconnecter et vous reconnecter.',
  'verify-now': 'Vérifiez maintenant',
  logout: 'Se déconnecter',
  'one-free-vote-per-contest': 'Vous avez un vote gratuit par concours comme d\'habitude, veuillez vous connecter pour le réclamer.',
  'sign-in-to-access': 'Vos votes pour ce candidat s\'afficheront ici, veuillez vous connecter pour accéder à cette option',
  'input-phone-without-code': 'Numéro de téléphone (sans indicatif de pays)',
  'sign-in-with-phone': 'Se connecter avec un numéro de téléphone',
  'search-country': 'Rechercher pays',
  'input-auth-code-via-sms': 'Code d\'entrée reçu par SMS',
  continue: 'Continuer',
  'code-sent-via-sms': 'Code envoyé par SMS',
  'code-sent-via-sms-failed': 'Échec de l\'envoi du code par SMS',
};

export default translation;
