import React, { Suspense, useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { routes } from './routes';
import Loading from './RouteLoading.component';
import Guarded from './guards/Guard.component';
import AppContext from '../App.context';
import PageTransitionWrapper from './PageTransitionWrapper.component';
import { useToaster } from '../hooks/toaster';
/* eslint-disable react/prop-types */
const Routing = () => {
  const appContext = useContext(AppContext);
  const toaster = useToaster();
  return (
    <>
      {
        appContext
          ? (
            <Router>
              <Suspense fallback={<Loading />}>
                <Switch>
                  {
                    routes.map(
                      (route) => (
                        <Route
                            key={route.path || '404'}
                            path={route.path}
                            {...(route.props || {})}
                            exact
                            render={(props) => (
                              <PageTransitionWrapper>
                                {
                                  route.guards
                                    ? (
                                      <Guarded
                                          route={route}
                                          {...(props || {})}
                                          {...(appContext || {})}
                                          toaster={toaster}
                                      />
                                    )
                                    : (
                                      <route.page
                                          toaster={toaster}
                                          {...(props || {})}
                                          {...(appContext || {})}
                                      />
                                    )
                                }
                              </PageTransitionWrapper>
                            )}
                        />
                      ),
                    )
                  }
                </Switch>
              </Suspense>
            </Router>
          )
          : <Loading />
      }
    </>
  );
};

export default Routing;
