import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoadingOverlay from '../components/overlay/LoadingOverlay.component';
import { useToaster } from '../hooks/toaster';
import Snack from '../components/snack/Snack.component';

function PageTransitionWrapper({ className, children }) {
  const { loader } = useSelector((state) => state);
  const { messages, slice } = useToaster();
  const { t } = useTranslation();
  useEffect(() => {
    if (loader?.loading) {
      // add class
      document.body.classList.add('loading-inside-123');
    } else {
      // remove class
      document.body.classList.remove('loading-inside-123');
    }
  }, [loader]);
  return (
    <div className={className} style={{ minHeight: `${loader?.loading ? '100vh' : '0'}` }}>
      { children }
      {
        loader?.loading ? (
          <LoadingOverlay style={{ position: 'fixed', minHeight: '100vh' }} text={t(loader?.text)} />
        ) : null
      }
      {
        messages?.length > 0
          ? <Snack toasted={slice()} />
          : null
      }
    </div>
  );
}

PageTransitionWrapper.defaultProps = {
  className: 'animate__animated animate__fadeIn',
};

PageTransitionWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default PageTransitionWrapper;
