import styled from '@emotion/styled';

export const StyledSpinner = styled.div`
    border: ${({ size }) => (size ?? 16)}px solid #ccc;
    border-top: ${({ size }) => (size ?? 16)}px solid #3498db;
    border-radius: 50%;
    height: ${({ size }) => (size ? size * 7.5 : 120)}px;
    width: ${({ size }) => (size ? size * 7.5 : 120)}px;
    animation: spin ${({ animationDuration }) => (animationDuration ?? 2)}s linear infinite;
    @keyframes spin {
        0% { transform: rotate(0deg);}
        100% { transform: rotate(360deg);}
    }
`;
