import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import {
  user,
  loader,
  toasterMessages,
  contests,
  contestPage,
  contestTotalPages,
} from './reducers';

const logger = (store) => (next) => (action) => {
  const returnValue = next(action);
  console.group(action.type);
  console.log('The action: ', action);
  console.log('The new state: ', store.getState());
  console.groupEnd();
  return returnValue;
};

export const reducers = {
  user,
  loader,
  toasterMessages,
  contests,
  contestPage,
  contestTotalPages,
};

const store = createStore(combineReducers(reducers), applyMiddleware(thunk, logger));

export default store;
