export const paths = {
  AUTH: '/auth',
  HOME: '/home',
  LANDING: '/',
  FORGOTPASSWORD: '/forgotpassword',
  RESETPASSWORD: '/resetpassword',
  MY_WORK: '/mywork',
  CONTEST_ORGANIZING_BUILDER: '/builder/organizing/:id',
  REDIRECT: '/3xx',
  CONTEST_PARTICIPATING: '/contest/participating/:id',
  CONTEST_DETAILS: '/contest/details/:id',
  CONTEST_SUBMISSIONS: '/contest/submissions/:id',
  SUBMISSION: '/submission/details/:id',
};

export const nonParameterized = (path) => {
  const index = path?.indexOf(':');
  if (index > 0) {
    return path.substring(0, index);
  }
  return path;
};

export default { paths };
