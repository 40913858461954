const translation = {
  start: 'Start',
  'make-impression': 'Make an Impression',
  login: 'Login',
  signup: 'Sign up',
  'login-with-phone': 'Login with phone',
  'login-with-facebook': 'Login with  facebook',
  'login-with-google': 'Login with Google',
  'privacy-consent-ok': 'By clicking {{page}}, you agree to/with our Terms. You can see how we process your data in our',
  'privacy-policy': 'Privacy Policy',
  'cookies-policy': 'Cookies Policy',
  'having-trouble-to': 'Having trouble to {{action}}?',
  'i-agree': 'I Agree',
  male: 'Male',
  female: 'Female',
  'have-a-profile': 'Have a profile?',
  'code-conduct-prompt': '{{appName}} is created to give singles a chance at finding their true partner. If you don not adhere to the following rules, your profile will be banned.',
  'create-your-profile': 'Create Your Profile',
  'create-an-honest-profile': 'Create an Honest Profile',
  'with-profile-benefit': 'With this, you can be able to meet people who match what you\'re looking for in a partner. Please be honest. Dishonesty may lead to sanctions.',
  'add-a-photo-of-yourself': 'Add a Photo of yourself',
  'your-face-photo-benefits': 'We\'ve seen that a good photo of your face and your full body may lead to better encounters. However, we require only one photo, with your face.',
  'be-respectful-and-polite': 'Be respectful and polite',
  'respect-others': 'Please treat other users with utmost respect.Being respectful and polite will earn you more chances to get into a fruitful encounter.',
  'signing-you-in': 'Signing you in, please wait...',
  authenticating: 'Authenticating, please wait...',
  working: 'Working, please wait...',
  next: 'Next',
  'what-s-your-name': 'What\'s your name?',
  'how-name-will-appear': 'This is how your name will appear on {{appName}}',
  'nice-to-meet-u': 'Nice to meet you, {{name}}',
  'what-s-ur-birthday': 'What\'s your Birthday?',
  'pay-with': 'Pay with',
  'order-details': 'Order Details',
  'input-phone-return-email': 'Please input a phone number for payment and an email for success notification',
  phone: 'Phone',
  'return-email': 'Return email',
  'enter-phone': 'Please type your phone number here',
  'enter-return-email': 'Please type your email here',
  summary: 'Summary',
  'item-description': 'Description',
  'per-unit': 'Per Unit',
  quantity: 'Quantity',
  total: 'Total',
  'original-price': 'Original Price',
  discount: 'Discount',
  'total-price': 'Total Price',
  proceed: 'Proceed',
  'your-votes': 'Your votes',
  'no-votes-to-display': 'You do not have any votes recorded. You may have voted as an honoured guest.',
  'vote-in-progress': 'Vote in progress. You will be notified once your payment is confirmed! Thanks!',
  'allow-popup': 'You may need to allow popups on your browser to continue',
  'popup-closed': 'You closed the popup window unexpectedly.',
  'email-not-verified': 'Email not verified. If you did so already, please logout and login again.',
  'verify-now': 'Verify Now',
  logout: 'Logout',
  'one-free-vote-per-contest': 'You have one free vote per contest as usual, please sign in to claim it.',
  'sign-in-to-access': 'Your votes for this contestant will show here, Please sign in to access this option',
  'input-phone-without-code': '(without country code) Phone number ',
  'sign-in-with-phone': 'Sign in with Phone number',
  'search-country': 'Search country',
  'input-auth-code-via-sms': 'Input code received via SMS',
  continue: 'Continue',
  'code-sent-via-sms': 'Code sent via SMS',
  'code-sent-via-sms-failed': 'Failed to send code via SMS',
};
export default translation;
