import React from 'react';
import ReactDOM from 'react-dom';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { v4 as uuid } from 'uuid';
import App from './App.component';
import reportWebVitals from './reportWebVitals';
import i18n from './i18n/i18n';
import store from './store';
import { setVoterId } from './components/vote-submission/storage';

initializeIcons();
setVoterId(uuid().replace(/-/g, ''));

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
