export const SESSION_REFS = {
  CONTESTS: 's-0001',
  CONTEST_PAGE: 's-0002',
  CONTEST_TOTAL_PAGE: 's-0003',
  CONTEST_PAGE_LOADED: 's-0004',
};

export const getSessionContests = () => JSON.parse(sessionStorage.getItem(SESSION_REFS.CONTESTS));

export const getSessionContestById = (id) => {
  const contests = getSessionContests();
  if (contests) {
    return contests[id];
  }
  return null;
};
export const setSessionContests = (contests) => {
  // make
  let toObject = getSessionContests() || {};
  if (Array.isArray(contests)) {
    contests.forEach(
      (contest) => {
        toObject[contest._id] = contest;
      },
    );
  } else if (typeof contests === 'object') {
    toObject = contests;
  }
  sessionStorage.setItem(SESSION_REFS.CONTESTS, JSON.stringify(toObject));
  return toObject;
};
export const updateSessionContestById = (id, body) => {
  const contests = getSessionContests();
  if (contests) {
    return setSessionContests({ ...contests, [id]: body });
  }
  return setSessionContests({ [id]: body });
};

export const setSessionContestPage = (page) => {
  sessionStorage.setItem(SESSION_REFS.CONTEST_PAGE, page);
};

export const getSessionContestPage = () => JSON.parse(
  sessionStorage.getItem(SESSION_REFS.CONTEST_PAGE),
);
export const setSessionContestTotalPage = (page) => {
  sessionStorage.setItem(SESSION_REFS.CONTEST_TOTAL_PAGE, page);
};

export const getSessionContestTotalPage = () => JSON.parse(
  sessionStorage.getItem(SESSION_REFS.CONTEST_TOTAL_PAGE),
);
export const clearSessionStorage = () => {
  Object.keys(SESSION_REFS).forEach(
    (k) => sessionStorage.removeItem(SESSION_REFS[k]),
  );
  console.log('[App] --- session cleared');
};
export const setSessionContestPageLoaded = () => {
  sessionStorage.setItem(SESSION_REFS.CONTEST_PAGE_LOADED, 'true');
};
export const getSessionContestPageLoaded = () => JSON.parse(
  sessionStorage.getItem(SESSION_REFS.CONTEST_PAGE_LOADED),
);
