import {
  SET_USER,
  LOADER,
  STACK_TOASTER_MESSAGE,
  SLICE_TOASTER_MESSAGE,
  SET_CONTESTS,
  UPDATE_CONTEST,
  SET_CONTEST_SUBMISSIONS,
  UPDATE_CONTEST_SUBMISSION,
  SET_CONTEST_PAGE,
  SET_CONTEST_TOTAL_PAGES,
} from '../actions';

export const user = (state = null, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    default:
      return state;
  }
};

export const loader = (state = { loading: false, text: '' }, action) => {
  switch (action.type) {
    case LOADER:
      return action.payload;
    default:
      return state;
  }
};

export const toasterMessages = (state = [], action) => {
  switch (action.type) {
    case STACK_TOASTER_MESSAGE:
      return [action.payload, ...(state ?? [])];
    case SLICE_TOASTER_MESSAGE:
      return state?.length > 0 ? state.slice(1) : state;
    default:
      return state;
  }
};

const arrayToObject = (arr) => {
  if (Array.isArray(arr)) {
    return arr.reduce((acc, contest) => {
      acc[contest._id] = contest;
      return acc;
    }, {});
  }
  return arr;
};

const updateContest = (state, payload) => {
  const _state = state;
  if (payload?._id) {
    _state[payload._id] = {
      ...(_state[payload._id] ?? {}),
      ...(payload ?? {}),
    };
  }
  return { ...(_state ?? {}) };
};

const setContestSubmissions = (state, payload) => {
  const _state = state;
  if (payload?._id) {
    _state[payload._id] = {
      ...(_state[payload._id] ?? {}),
      submissions: arrayToObject(payload.submissions),
    };
  }
  return { ...(_state ?? {}) };
};

const updateContestSubmission = (state, payload) => {
  const _state = state;
  if (payload?._id) {
    if (payload?.submission && payload?.submission?._id) {
      _state[payload._id] = {
        ...(_state[payload._id] ?? {}),
        submissions: {
          ...(_state[payload._id]?.submissions ?? {}),
          [payload.submission._id]: {
            ...((_state[payload._id]?.submissions ?? {})[payload.submission._id] ?? {}),
            ...(payload.submission ?? {}),
          },
        },
      };
    }
  }
  return { ...(_state ?? {}) };
};

export const contests = (state = {}, action) => {
  switch (action.type) {
    case SET_CONTESTS:
      return {
        ...state,
        ...arrayToObject(action.payload),
      };
    case UPDATE_CONTEST:
      return updateContest(state, action.payload);
    case SET_CONTEST_SUBMISSIONS:
      return setContestSubmissions(state, action.payload);
    case UPDATE_CONTEST_SUBMISSION:
      return updateContestSubmission(state, action.payload);
    default:
      return state;
  }
};

export const contestPage = (state = 1, action) => {
  switch (action.type) {
    case SET_CONTEST_PAGE:
      return action.payload;
    default:
      return state;
  }
};

export const contestTotalPages = (state = 1, action) => {
  switch (action.type) {
    case SET_CONTEST_TOTAL_PAGES:
      return action.payload;
    default:
      return state;
  }
};
