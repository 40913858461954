import React from 'react';
import PropTypes from 'prop-types';
import { StyledSpinner } from './Spinner.styles';

function Spinner({ size, animationDuration, ...props }) {
  return (
    <StyledSpinner size={size} animationDuration={animationDuration} {...props} />
  );
}

Spinner.defaultProps = {
  size: 5,
  animationDuration: 1,
};

Spinner.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  animationDuration: PropTypes.number,
};

export default Spinner;
