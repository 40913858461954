import { getLocalUser } from '../../persistence/local.storage';

export const guestGuard = (props) => {
  const u = getLocalUser();
  if (u) {
    props.history?.goBack();
    return '/';
  }
  return true;
};
