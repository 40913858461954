import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { paths } from '../paths';

function Guard({ route, ...props }) {
  let component = <route.page {...props} />;
  if (route.guards.length === 0) return component;
  for (let i = 0; i < route.guards.length; i += 1) {
    const guard = route.guards[i];
    const { location } = props;
    const g = guard({ ...props });
    if (typeof g === 'boolean') {
      if (!g) {
        component = (
          <Redirect
              to={{ pathname: paths.AUTH, state: { from: location } }}
              {...props}
          />
        );
        break;
      }
    } else if (typeof g === 'string') {
      component = (
        <Redirect
            to={{ pathname: g, state: { from: location } }}
            {...props}
        />
      );
      break;
    } else {
      component = (
        <Redirect
            to={{ pathname: paths.AUTH, state: { from: location } }}
            {...props}
        />
      );
      break;
    }
  }
  return component;
}

Guard.propTypes = {
  route: PropTypes.object.isRequired,
};

export default Guard;
