import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Overlay from './Overlay.component';
import Spinner from '../loaders/spinner/Spinner.component';

function LoadingOverlay({ text, ...props }) {
  const { t } = useTranslation();
  return (
    <Overlay {...props}>
      <Spinner size={8} />
      { t(text) }
    </Overlay>
  );
}

LoadingOverlay.defaultProps = {
  text: '',
};

LoadingOverlay.propTypes = {
  text: PropTypes.string,
};

export default LoadingOverlay;
