import {
  createContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import { searchParams } from './routes/utils';
import { getAppConfig, setAppConfig as setAppWideConfig } from './config/app.config';
import { clearLocalUser, clearTokens, getLocalUser } from './persistence/local.storage';
import {
  clearSessionStorage,
  getSessionContestPage,
  getSessionContestPageLoaded,
  getSessionContests,
  getSessionContestTotalPage,
  setSessionContestPageLoaded,
} from './persistence/session.storage';
import { useContests } from './hooks';

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthed, setIsAuthed] = useState(false);
  const [appConfig, setAppConfig] = useState(null);
  const [apiHits, setApiHits] = useState(0);
  const [isContestPageLoaded, setIsContestPageLoaded] = useState(false);
  const animateBase = 'animate__animated';
  const pageClassNames = {
    fadeIn: `${animateBase} animate__fadeIn`,
    fadeInDown: `${animateBase} animate__fadeInDown`,
  };
  useEffect(() => {
    const u = getLocalUser();
    if (u === null) {
      setIsAuthed(false);
    } else {
      setUser(JSON.parse(u));
      setIsAuthed(true);
    }
  }, [getLocalUser]);
  const { setContests, setPage, setTotalPages } = useContests();
  const loadContests = () => {
    const cts = getSessionContests();
    const p = getSessionContestPage();
    const tP = getSessionContestTotalPage();
    const isL = getSessionContestPageLoaded();
    if (cts) {
      setContests(cts);
    }
    if (p) {
      setPage(p);
    }
    if (tP) {
      setTotalPages(tP);
    }
    if (isL) {
      setIsContestPageLoaded(true);
    }
  };
  useEffect(() => {
    loadContests();
  }, []);
  useEffect(() => {
    getAppConfig().then(
      (config) => {
        setAppConfig(config);
        setAppWideConfig(config);
      },
    );
  }, [setAppConfig]);

  const handleSetIsContestPageLoaded = () => {
    setIsContestPageLoaded(true);
    setSessionContestPageLoaded();
  };
  const handleSetUser = (u) => {
    clearSessionStorage();
    setContests(null);
    setPage(1);
    setTotalPages(1);
    setUser(u);
  };
  const logout = () => {
    clearLocalUser();
    clearTokens();
    setIsAuthed(false);
    setUser(null);
    clearSessionStorage();
    setContests(null);
    setPage(1);
    setTotalPages(1);
    window.FB?.logout(() => console.log('Facebook logout successful'));
    window.location.reload();
  };

  return (
    <AppContext.Provider value={{
      pageClassNames,
      isAuthed,
      user,
      queryParams: searchParams,
      setUser: handleSetUser,
      setIsAuthed,
      appConfig,
      apiHits,
      setApiHits,
      isContestPageLoaded,
      setIsContestPageLoaded: handleSetIsContestPageLoaded,
      logout,
    }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppContext;
