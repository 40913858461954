import styled from '@emotion/styled';

export const StyledOverlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 11000;
    overflow: hidden;
    background: rgba(0,0,0, ${({ isDark }) => (isDark ? '0.6' : '0.2')});
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    & > * {
        margin-bottom: 15px;
    }
`;
