import React from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { MessageBar, MessageBarButton } from '@fluentui/react';
import { StyledSnack, StyledSnackWrapper } from './Snack.styles';

const Snack = ({ toasted }) => {
  const {
    type, message, position, dismissible, actions,
  } = toasted;
  const actionsProps = actions ? {
    actions: (
      <div>
        {
          actions?.map(
            (action) => (
              <MessageBarButton
                  key={action.text ?? 'ok-key'}
                  onClick={() => {
                    if (action.onAction) {
                      action.onAction();
                    }
                    if (action.dismissOnAction) {
                      if (toasted.dismissible && toasted.dismiss) {
                        toasted.dismiss();
                      }
                    }
                  }}
              >
                { action.text ?? 'OK' }
              </MessageBarButton>
            ),
          )
        }
      </div>
    ),
  } : {};
  return (
    <StyledSnackWrapper position={position}>
      <MessageBar messageBarType={type} {...actionsProps}>
        <StyledSnack>
          <span>{message}</span>
          {
            dismissible
              && (
              <button type="button" onClick={toasted.dismiss}>
                <FontIcon iconName="Cancel" style={{ fontSize: 15 }} />
              </button>
              )
          }
        </StyledSnack>
      </MessageBar>
    </StyledSnackWrapper>
  );
};

Snack.defaultProps = {
  toasted: {},
};

Snack.propTypes = {
  toasted: PropTypes.object,
};

export default Snack;
