import { useSelector, useDispatch } from 'react-redux';
import { sliceToasterMessageAction, stackToasterMessageAction } from '../store/actions';

export const useToaster = () => {
  const { toasterMessages } = useSelector((state) => state);
  const dispatch = useDispatch();
  const toasterStack = ({
    type, message, timeout, position, dismissible, actions,
  }) => {
    dispatch(stackToasterMessageAction({
      type, message, timeout, position, dismissible, actions,
    }));
  };
  const dismissToast = () => dispatch(sliceToasterMessageAction());
  const toasterSlice = () => {
    if (toasterMessages.length > 0) {
      const [toast] = toasterMessages;
      if (!toast.dismissible || toast.timeout > 0) {
        const timeoutId = setTimeout(dismissToast, (toast.timeout || 5) * 1000);
        toast.dismiss = () => {
          clearTimeout(timeoutId);
          dismissToast();
        };
      } else {
        toast.dismiss = dismissToast;
      }
      return toast;
    }
    return null;
  };
  return {
    toast: toasterStack,
    slice: toasterSlice,
    messages: toasterMessages,
  };
};
