import React from 'react';
import PropTypes from 'prop-types';
import { StyledOverlay } from './Overlay.styles';

function Overlay({ children, ...props }) {
  return (
    <StyledOverlay {...props}>
      { children }
    </StyledOverlay>
  );
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Overlay;
