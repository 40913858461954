import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

const Loader = ({
  fill, size, ...props
}) => (
  <Spinner size={size} fill={fill} {...props} />
);
Loader.defaultProps = {
  size: SpinnerSize.large,
  fill: 'var(--color-primary-tint)',
};
Loader.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fill: PropTypes.string,
};

export default Loader;
