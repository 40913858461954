import React from 'react';

import Loading from '../components/loader/Loader';

const RouteLoading = () => (
  <div
      style={{
        margin: 'auto',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
  >
    <div style={{ width: '10vw', height: '10vh', margin: 'auto' }}>
      <Loading size={3} id="route-loader-1" />
    </div>
  </div>
);

export default RouteLoading;
